@tailwind base;
@tailwind components;
@tailwind utilities;


/* Ensure all content is contained within the viewport width */
@media (max-width: 640px) {
    html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  
}
    * {
      box-sizing: border-box;
      max-width: 100%;
    }
  }
    /* color: #ccc;
    font-weight: 400;
    background-color: #6f7981;
    input[type="file"] {
        color: transparent !important;
    }
    input[type="file"]::before {
        margin-right: 10px;
    }

    .timer-wrapper {
        display: flex;
        justify-content: center;
    }

    .timer {
        font-family: "Montserrat";
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .text {
        color: #aaa;
    }

    .timer-value {
        font-size: 70px;
        font-family: var(--font-primary);
    } */

